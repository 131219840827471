<script>
import Layout from "../../layouts/main";
import Filters from '../../../components/filters.vue';
import SearchBy from '../../../components/search-by.vue';
import DownloadBtn from '../../../components/download-btn.vue';
import PerPage from '../../../components/per-page.vue';
import Multiselect from 'vue-multiselect';
import * as moment from "moment";

import {
	FETCH_INVOICES,
    EXPORT_INVOICES,
    FETCH_INVOICES_TEMPLATES,
    INVOICES_REMINDER,
    FETCH_MERCHANTS_LIST,
    FETCH_SOURCE_LIST,
    FETCH_DETAILS_FOR_ALL_SUB_USERS
} from "@/state/actions.type";
export default {
    components: {
        Layout,
        Filters,
        SearchBy,
        DownloadBtn,
        PerPage,
        Multiselect
    },
    data() {
        return {
            searchBy: {
                key: "invoice_ref",
                value: ""
            },
            createdByFilter: null,
            merchant_uuids:[],
            searchByOptions: ['Invoice Ref','Merchant Invoice Ref', 'Description', 'Customer Name', 'Customer Email', 'Customer Phone' ],
            isFetching: false,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            fields: [
                { key: "created_at", label: "Date", sortable: true },
                { key: "invoice_ref", label: "Invoice Ref" },
                { key: "merchant_invoice_ref", label: "Merchant Invoice Ref" },
                { key: "customer_name", label: "Customer Name",sortable: true },
                { key: "status", label: "Status" },
                { key: "due_date", label: "Due Date",sortable: true },
                { key: "total_amount", label: "Total Amount" },
                { key: "last_reminder_sent_at", label: "Last Reminder Sent",sortable: true },
                { key: "show_details", sortable: false, label: '' },
            ],
            from: "",
            to: "",
            filters: {
                status: [],
                source: "",
                from: "",
                to: ""
            },
            isFilterApplied: false,
            showTemplateDropDown:false,
            pdf_link:"",
            merchant_ref:"",
            sorting_data: {
                "sort_by_key": "",
                "order": ""
            },
        }
    },
    computed: {
        invoicesDataFromStore() {
             return this.$store.state.invoices.content || [];
        },
        invoicesTemplatesDataFromStore() {
             return this.$store.state.invoices.templateContent || [];
        },
        rows() {
            return this.$store.state.invoices.total_elements;
        },
        userPermissions() {
            return this.$store.getters["auth/getUserPermissionsList"];
        },

        userRoles() {
            return this.$store.getters['auth/getUserRolesList'];
        },
        merchantListDataFromStore() {
            return this.$store.state.masterMerchant.content;
        },
        isMasterMerchant() {
            return this.$store.state.auth.currentUser.is_master_merchant;
        },
        lastCurrency() {
            return this.invoicesDataFromStore.length > 0 ? this.invoicesDataFromStore[0].currency : "";
        },

        lastCountry() {
            return this.invoicesDataFromStore.length > 0 ? this.invoicesDataFromStore[0].countryCode : "";
        },
        isGlobalUser() {
            return this.$store.state.auth.currentUser.is_global_user;
        },

        subUserList() {
            return this.$store.state.accounts.subUserData || [];
        },
        sourceList() {
            return this.$store.state.paymentlinks.sourceList;
        },
    },
    created() {
        this.$store.dispatch(`paymentlinks/${FETCH_SOURCE_LIST}`);
        if(this.isMasterMerchant){
            this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_LIST}`, {
                pageIndex: 1,
                pagesize: 10,
                filters: { source: "default" },
            });
        }else{
            if(this.isGlobalUser) {
                this.$store.dispatch(`accounts/${FETCH_DETAILS_FOR_ALL_SUB_USERS}`);
            }
            this.$store.dispatch(`invoices/${FETCH_INVOICES_TEMPLATES}`);
        }
    },
    filters: {
        date(value) {
            return moment(value).format("DD MMM YYYY");
        },
        time(value) {
            return 'at ' + moment(value).format("hh:mm A");
        },
    },
    methods: {
        fetchInvoices() {
            let filterTosend = this.getFiltersDetails();
            const payload = {
                page: this.currentPage,
                pagesize: this.perPage,
                to: this.filters.to,
                from: this.filters.from,
                filters: filterTosend,
                sorting_data: this.sorting_data
            };
            if(this.isMasterMerchant) {
                payload.merchant_keys = this.merchant_uuids.map(function (item) {
                return item.key;
                });
            }
            this.$store.dispatch(`invoices/${FETCH_INVOICES}`, {
                payload: payload,
                isMasterMerchant: this.isMasterMerchant,
            }).then(() => {
                if(this.filterApplied()) {
                this.isFilterApplied = true;
                } else {
                this.isFilterApplied = false;
                }
            });
        },
        getFiltersDetails(){
            this.filters.from = "";
            this.filters.to = "";

            if(this.from) {
                this.filters.from = new Date(moment(this.from, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss')); 
                this.filters.from =this.filters.from.toISOString();
            } else {
                this.filters.from = new Date(moment('2018-01-01T00:00:00.000').format('YYYY-MM-DD HH:mm:ss'))
                this.filters.from =this.filters.from.toISOString();
            }
            if(this.to) {
                this.filters.to = new Date(moment(this.to, 'DD-MM-YYYY HH:mm').format('YYYY-MM-DD HH:mm:ss'))
                this.filters.to =this.filters.to.toISOString();
            } else {
                this.filters.to = new Date(moment().format('YYYY-MM-DD HH:mm:ss'))
                this.filters.to =this.filters.to.toISOString();
            }

            let extrafilters = {};
            if(this.searchBy.value !== ""){
                if(this.searchBy.key == "Invoice Ref") {
                extrafilters['invoice_ref'] = this.searchBy.value.trim() 
                }else if(this.searchBy.key == "Merchant Invoice Ref") {
                extrafilters['merchant_invoice_ref'] = this.searchBy.value.trim()
                }else if(this.searchBy.key == "Description") {
                extrafilters['description'] = this.searchBy.value.trim()
                }
                else if(this.searchBy.key == "Customer Name") {
                extrafilters['customer_name'] = this.searchBy.value.trim()
                } 
                else if(this.searchBy.key == "Customer Email") {
                extrafilters['customer_email_address'] = this.searchBy.value.trim()
                } 
                else if(this.searchBy.key == "Customer Phone"){
                extrafilters['customer_phone_number'] = this.searchBy.value.trim()
                }
            }
            if(this.isGlobalUser && this.createdByFilter) {
                this.filters.created_by = this.createdByFilter.uuid;
            } else {
                delete this.filters.created_by;
            }
            let filtersTosend = {...this.filters,...extrafilters};
            return filtersTosend
        },
        dateChanged(value) {
            this.from = value[0];
            this.to = value[1];

            this.fetchInvoices();
        },
        resetAdvanceFilters() {
            if(this.isFilterApplied) {
                this.filters = {
                    from: "",
                    to: "",
                    status:[],
                    source:"",
                };
                this.fetchInvoices();
                this.createdByFilter = null;
            }
            this.$root.$emit('bv::toggle::collapse', 'invoice-filter-collapse')
        },
        openInvoiceDetailsPage(pageRef, submerchant_key) {
            this.$router.push({path: '/invoices/invoices-details', query: { ref: pageRef, key: this.isMasterMerchant ? submerchant_key : '' }});
        },
        filterApplied() {
            return Boolean((this.filters.status && this.filters.status.length)) || Boolean(this.createdByFilter);
        },
        templateDropDown(){
            this.showTemplateDropDown = !this.showTemplateDropDown;
        },
        exportInvoicesInExcel() {
            let filterTosend = this.getFiltersDetails();
            const payload = {
                page: this.currentPage,
                pagesize: this.perPage,
                to: this.filters.to,
                from: this.filters.from,
                filters: filterTosend,
                sorting_data: this.sorting_data
            };
            if(this.isMasterMerchant) {
                payload.merchant_keys = this.merchant_uuids.map(function (item) {
                    return item.key;
                });
            }
            this.$store.dispatch(`invoices/${EXPORT_INVOICES}`, {
                payload: payload,
                isMasterMerchant: this.isMasterMerchant,
            });
        },
        hideChooseTemplateModal() {
            this.$refs['modal-2'].hide();
        },
        showChooseTemplateModal(){
            this.showTemplateDropDown = false;
            this.$refs['modal-2'].show();
        },
        preview_as_pdf(pdf,merchant_ref){
            this.pdf_link = pdf+'#toolbar=0';
            this.merchant_ref = merchant_ref;
            this.$refs['preview_payslip'].show();
        },
        downloadPdf(pdf) {
            window.open(pdf, '_blank');
        },
        sendReminder(ref){
            this.$store.dispatch(`invoices/${INVOICES_REMINDER}`, ref)
            .then((response) => {
                console.log(response);
                this.$notify({
                type: "success",
                text: "Reminder Successfully Sended",
                closeOnClick: true,
                });
            })
            .catch((error) => {
                // error
                console.log("error", error);
                this.$notify({
                    type: "error",
                    text: 'Failed To send Reminder.',
                    closeOnClick: true,
                });
            });
        },
        sortInvoices(item) {
            this.sorting_data.sort_by_key = item.sortBy;
            if(item.sortDesc) {
                this.sorting_data.order = "DESC";
            } else {
                this.sorting_data.order = "ASC";
            }
            this.fetchInvoices();
        },
    },
    directives: {
        'click-outside': {
        bind (el, binding, vnode) {
            const handleOutsideClick = (e) => {
            e.stopPropagation()
            const { handler, exclude } = binding.value
            const excludedEl = vnode.context.$refs[exclude]

            let clickedOnExcludedEl = !excludedEl || excludedEl.contains(e.target)

            if (!el.contains(e.target) && !clickedOnExcludedEl && vnode.context.showTemplateDropDown) {
                vnode.context[handler]();
            }
            }
            document.addEventListener('click', handleOutsideClick)
            document.addEventListener('touchstart', handleOutsideClick)
        },
        }
    }
}
</script>

<template>
    <Layout>
        <div style="position: relative;" class="d-flex justify-content-end" v-if="!isMasterMerchant && (userPermissions.includes('create-invoices') || userRoles.includes('admin') || userRoles.includes('sub-admin'))">
            <button @click="templateDropDown" ref="templateDropDown-button" class="btn btn-primary btn-sm"
            style="padding: 12px 16px;font-weight: 400;font-size: 14px;line-height: 20px;">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <path d="M4.5 9H13.5M9 13.5V4.5" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                Create New Invoice
            </button>
            <div
              v-click-outside="{
                exclude: 'templateDropDown-button',
                handler: 'templateDropDown'
              }"
            >
                <div v-if="showTemplateDropDown" class="select-template-container">
                    <router-link :to="{ name: 'Invoices Create', query: {lastCurrency, lastCountry} }">
                        <div class="select-template-section">
                            <div style="margin-right: 10px;">
                                <div class="name">New Invoice</div>
                                <div class="sub-text">Create a new invoice.</div>
                            </div>
                            <div class="template-section-img">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                    <g id="Iconsax/Linear/arrowdown2">
                                        <path id="Vector" d="M6.7124 3.06043L11.6024 7.95043C12.1799 8.52793 12.1799 9.47293 11.6024 10.0504L6.7124 14.9404" stroke="#FC6B2D" stroke-width="2.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                    </g>
                                </svg>
                            </div>
                        </div>
                    </router-link>
                    <div @click="showChooseTemplateModal" class="select-template-section">
                        <div style="margin-right: 10px;">
                            <div class="name">Choose Template</div>
                            <div class="sub-text">Select from your existing templates.</div>
                        </div>
                        <div class="template-section-img">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                <g id="Iconsax/Linear/arrowdown2">
                                    <path id="Vector" d="M6.7124 3.06043L11.6024 7.95043C12.1799 8.52793 12.1799 9.47293 11.6024 10.0504L6.7124 14.9404" stroke="#FC6B2D" stroke-width="2.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" v-if="isMasterMerchant">
            <div class="col-sm-12 col-md-9">
                <!-- do nothing -->
            </div>
            <div class="col-sm-12 col-md-3">
                <multiselect
                    v-model="merchant_uuids"
                    :options="merchantListDataFromStore"
                    @input="fetchInvoices"
                    placeholder="Search Merchants"
                    track-by="email_id"
                    label="email_id"
                    :searchable="true"
                    :multiple="true"
                ></multiselect>
            </div>
        </div>

        <filters class="mt-4 mb-3" @date-change="dateChanged" @toggle-filter="resetAdvanceFilters" />

        <div class="row">
            <div class="col-sm-12 col-md-12">
            <b-collapse id="invoice-filter-collapse">
                <b-card style="box-shadow: none;border-radius: 15px;border: 1px #DCDAD5 solid !important ;margin-bottom: 15px;" class="border border-light">
                <div class="row mt-3">
                    <div class="col-sm-12 col-md-6 col-xl-4" v-if="isGlobalUser && !isMasterMerchant">
                        <label for="enddate" style="display: block;">{{ "Created By" }}</label>
                        <multiselect
                            v-model="createdByFilter"
                            placeholder="Search User"
                            :searchable="true"
                            :options="subUserList"
                            track-by="uuid"
                            label="email_address"
                            :multiple="false"
                        ></multiselect>
                    </div>
                    <div class="col-sm-12 col-md-6 col-xl-4">
                        <label for="enddate" style="display: block;">{{ "Invoice Status" }}</label>
                        <multiselect
                            v-model="filters.status"
                            placeholder="Invoice Status"
                            :searchable="true"
                            :options="['Sent', 'Overdue', 'Refunded', 'Paid', 'Created','Voided', 'Scheduled', 'Partially Refunded', 'Paid Late']"
                            :multiple="true"
                            class="sm-multiselect"
                        ></multiselect>
                    </div>
                    <div class="col-sm-12 col-md-6 col-xl-4">
                        <label for="enddate" style="display: block;">{{ "Source" }}</label>
                        <multiselect
                            v-model="filters.source"
                            placeholder="Search Source"
                            :searchable="true"
                            :options="sourceList"
                            :multiple="false"
                        ></multiselect>
                    </div>
                </div>
                <div class="d-flex justify-content-between mt-3">
                    <b-btn variant="link" @click="resetAdvanceFilters" class="text-chai font-16px">Clear Filters</b-btn>
                    <div>
                    <b-btn variant="primary" @click="resetAdvanceFilters"  class="mr-2 cancel bg-white text-dark">Cancel</b-btn>
                    <b-btn variant="primary" class="btn-padding" @click="fetchInvoices">Save</b-btn>
                    </div>
                </div>
                </b-card>
            </b-collapse>
            </div>
        </div>

        <div class="d-flex justify-content-between my-3">
            <search-by :options="searchByOptions" v-model="searchBy" @search-by-clicked="fetchInvoices" />
            <download-btn @download-button-clicked="exportInvoicesInExcel" />
        </div>

        <!-- @sort-changed="sortTransactions" -->
        <b-table
            class="table-custom"
            :items="invoicesDataFromStore"
            :fields="fields"
            responsive="sm"
            @sort-changed="sortInvoices"
            sticky-header="50vh"
            show-empty
            no-local-sorting
            :busy="isFetching"
            :empty-text="$t('common.no-records')"
            hover
        >
            <template #table-busy>
                <b-spinner class="align-middle" :small="true"></b-spinner>
            </template>
            <template v-slot:head()="data">
                <span>
                    {{ $t(data.label) }}
                    <img v-show="data.field.sortable && sorting_data.sort_by_key !== data.column" class="ml-2" src="@/assets/images/arrow-3.svg" alt="sort_up">
                    <img v-show="data.field.sortable && sorting_data.sort_by_key === data.column && sorting_data.order === 'ASC'" class="ml-2" src="@/assets/images/sort_asc.svg" alt="sort_asc">
                    <img v-show="data.field.sortable && sorting_data.sort_by_key === data.column && sorting_data.order === 'DESC'" class="ml-2" src="@/assets/images/sort_desc.svg" alt="sort_desc">
                </span>
            </template>
            <template #cell(created_at)="row">
                <p class="table-date">{{ row.value | date }}</p>
                <p class="table-time">{{ row.value | time }}</p>
            </template>
             <template #cell(due_date)="row">
                 <div v-if="row.item.status == 'Overdue'">
                    Overdue
                        <b-dropdown class="overdue_reminder" size="sm" variant="white" dropright>
                            <template v-slot:button-content>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 16 16" fill="none">
                                    <g id="vuesax/bold/notification-bing">
                                    <g id="notification-bing">
                                    <path id="Vector" d="M13.46 9.37288L12.7066 8.11954C12.54 7.84621 12.3933 7.31954 12.3933 6.99954V5.75288C12.3933 3.33288 10.4266 1.36621 8.01331 1.36621C5.59331 1.37288 3.62664 3.33288 3.62664 5.75288V6.99288C3.62664 7.31288 3.47997 7.83954 3.31997 8.11288L2.56664 9.36621C2.27997 9.85288 2.21331 10.4062 2.39331 10.8862C2.57331 11.3729 2.97997 11.7595 3.51331 11.9329C4.23331 12.1729 4.95997 12.3462 5.69997 12.4729C5.77331 12.4862 5.84664 12.4929 5.91997 12.5062C6.01331 12.5195 6.11331 12.5329 6.21331 12.5462C6.38664 12.5729 6.55997 12.5929 6.73997 12.6062C7.15997 12.6462 7.58664 12.6662 8.01331 12.6662C8.43331 12.6662 8.85331 12.6462 9.26664 12.6062C9.41997 12.5929 9.57331 12.5795 9.71997 12.5595C9.83997 12.5462 9.95997 12.5329 10.08 12.5129C10.1533 12.5062 10.2266 12.4929 10.3 12.4795C11.0466 12.3595 11.7866 12.1729 12.5066 11.9329C13.02 11.7595 13.4133 11.3729 13.6 10.8795C13.7866 10.3795 13.7333 9.83288 13.46 9.37288ZM8.49997 6.66621C8.49997 6.94621 8.27331 7.17288 7.99331 7.17288C7.71331 7.17288 7.48664 6.94621 7.48664 6.66621V4.59954C7.48664 4.31954 7.71331 4.09288 7.99331 4.09288C8.27331 4.09288 8.49997 4.31954 8.49997 4.59954V6.66621Z" fill="#FC6B2D"/>
                                    <path id="Vector_2" d="M9.88678 13.3397C9.60678 14.113 8.86678 14.6663 8.00012 14.6663C7.47345 14.6663 6.95345 14.453 6.58678 14.073C6.37345 13.873 6.21345 13.6063 6.12012 13.333C6.20678 13.3463 6.29345 13.353 6.38678 13.3663C6.54012 13.3863 6.70012 13.4063 6.86012 13.4197C7.24012 13.453 7.62678 13.473 8.01345 13.473C8.39345 13.473 8.77345 13.453 9.14678 13.4197C9.28678 13.4063 9.42678 13.3997 9.56012 13.3797C9.66678 13.3663 9.77345 13.353 9.88678 13.3397Z" fill="#FC6B2D"/>
                                    </g>
                                    </g>
                                </svg>
                            </template>
                        <b-dropdown-item >
                            <div>
                                <span style="color: rgba(0, 0, 0, 0.50);font-size: 12px;line-height: 20px;">UNPAID INVOICE : {{ row.value | date }}</span>
                                <div style="font-size: 12px;line-height: 20px; margin-top: 12px;">
                                    <span style="display: block;margin-bottom: 20px;">Dear {{ row.item.customer_name }},</span>We're reaching out to you to inform about your unpaid invoice. Please find it attached.
                                </div>
                                <div @click="sendReminder(row.item.invoice_ref)" style="background: black;border-radius: 12px;color: #fff;border: 2px black solid;flex-direction: column;justify-content: center;align-items: flex-start;gap: 10px;display: inline-flex;margin-top: 20px;padding: 8px 16px;font-size: 14px;">Send Reminder</div>
                            </div>
                        </b-dropdown-item>
                    </b-dropdown>
                 </div>
                <p v-else class="table-date">{{ row.value | date }}</p>
            </template>

            <!-- <template #cell(merchant_invoice_ref)="row">
                <span style="color: #FC6B2D;cursor: pointer;" @click="openInvoiceDetailsPage(row.item.invoice_ref, row.item.merchant_key)">{{row.value}}</span>
            </template> -->

            <template #cell(invoice_ref)="row">
                <span style="color: #FC6B2D;cursor: pointer;" @click="openInvoiceDetailsPage(row.item.invoice_ref, row.item.merchant_key)">{{row.value}}</span>
            </template>
            <template #cell(last_reminder_sent_at)="row">
                <p style="margin-bottom: 0px;" v-if="row.value" class="table-date">{{ row.value | date }}</p>
                <p style="margin-bottom: 0px;" v-else> N/A </p>
            </template>
            <template v-slot:cell(total_amount)="{ value, item }">
                {{ (value).toLocaleString('en-US', {style : 'currency', currency : item.currency}) }}
            </template>
            <template v-slot:cell(status)="row">
                <div
                class="badge font-size-12"
                style="padding: 6px 12px; border-radius: 8px; font-size: 12px; line-height: 20px;"
                :class="{
                    'sent-status':`${row.value}` === 'Sent',
                    'due-status':`${row.value}` === 'Overdue',
                    'draft-status':`${row.value}` === 'Draft',
                    'paid-late-status':`${row.value}` === 'Paid Late',
                    'badge-soft-danger':
                    `${row.value}` === 'Voided',
                    'badge-soft-success': `${row.value}` === 'Paid',
                    'badge-soft-warning': `${row.value}` === 'Scheduled',
                    'created-status': row.value === 'Created',
                    'badge-orange': row.value === 'Under Review',
                    'refunded': row.value === 'Refunded',
                    'partial-refund': row.value === 'Partially Refunded'
                }"
                >
                {{ row.value }}
                </div>
            </template>
            <template #cell(show_details)="row">
                <div style="display: flex;align-items: center;gap: 10px; cursor: pointer;">
                    <div @click="downloadPdf(row.item.invoice_url)" style="padding: 10px 10px;border-radius: 8px;background: rgba(220, 218, 213, 0.30); height: 36px;">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                            <g opacity="0.5">
                            <path d="M10.96 5.93359C13.36 6.14026 14.34 7.37359 14.34 10.0736V10.1603C14.34 13.1403 13.1467 14.3336 10.1667 14.3336H5.82665C2.84665 14.3336 1.65332 13.1403 1.65332 10.1603V10.0736C1.65332 7.39359 2.61999 6.16026 4.97999 5.94026" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M8 1.33301V9.91967" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M10.2333 8.43359L7.99994 10.6669L5.7666 8.43359" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        </svg>
                    </div>
                    <b-dropdown class="show_details" size="sm" variant="white" dropleft>
                        <template v-slot:button-content>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <g id="Iconsax/Linear/more">
                                <path id="Vector" d="M6.6665 12.6667C6.6665 13.4 7.2665 14 7.99984 14C8.73317 14 9.33317 13.4 9.33317 12.6667C9.33317 11.9333 8.73317 11.3333 7.99984 11.3333C7.2665 11.3333 6.6665 11.9333 6.6665 12.6667ZM6.6665 3.33333C6.6665 4.06667 7.2665 4.66667 7.99984 4.66667C8.73317 4.66667 9.33317 4.06667 9.33317 3.33333C9.33317 2.6 8.73317 2 7.99984 2C7.2665 2 6.6665 2.6 6.6665 3.33333ZM6.6665 8C6.6665 8.73333 7.2665 9.33333 7.99984 9.33333C8.73317 9.33333 9.33317 8.73333 9.33317 8C9.33317 7.26667 8.73317 6.66667 7.99984 6.66667C7.2665 6.66667 6.6665 7.26667 6.6665 8Z" stroke="black" stroke-opacity="0.3"/>
                                </g>
                            </svg>
                        </template>
                        <b-dropdown-item-button v-if="!isMasterMerchant" @click="$router.push({ name: 'Invoices Create', query: { invoiceRef: row.item.invoice_ref } })">
                            Duplicate
                        </b-dropdown-item-button>
                        <b-dropdown-item-button v-if="row.item.status == 'Sent'" @click="sendReminder(row.item.invoice_ref)">Send Reminder</b-dropdown-item-button>
                        <b-dropdown-item-button  @click="preview_as_pdf(row.item.invoice_url, row.item.merchant_invoice_ref)">Preview as PDF</b-dropdown-item-button>
                        <!-- <b-dropdown-item-button  @click="deletePaymentLink(row.item.link_ref)">Delete Invoice</b-dropdown-item-button> -->
                    </b-dropdown>
                </div>
            </template>
        </b-table>

        <div class="d-flex justify-content-between">
            <per-page
                v-model="perPage"
                :currentPage="currentPage"
                :totalRows="rows"
                :tableRowsCount="1"
                @input="fetchInvoices"
            />
            <!-- invoicesDataFromStore.length -->
            <b-pagination
                class="my-auto"
                v-model="currentPage"
                :current-page="currentPage"
                :total-rows="rows"
                :per-page="perPage"
                @input="fetchInvoices"
            ></b-pagination>
        </div>

        <b-modal
            id="modal-2"
            ref="modal-2"
            title=""
            hide-footer
            no-close-on-backdrop
            header-class="modal-header-custom"
            body-class="modal-body-custom-invoice"
            footer-class="modal-footer-custom"
        >
            <div class="">
                <div class="text-black" style="font-size: 20px; margin-bottom: 5px;line-height: 20px;">Choose Templates</div>
                <div style="color: rgba(0, 0, 0, 0.5);color: rgba(0, 0, 0, 0.70);font-size: 12px;font-weight: 325;line-height: 20px;">Choose from your existing templates.</div>
                <div v-for="(item, key) in invoicesTemplatesDataFromStore" :key="key">
                    <router-link :to="{ name: 'Invoices Create', query: { templateRef: item.template_ref } }">
                    <div class="template-section">
                        <div>
                            <div class="name">{{item.template_name}}</div>
                            <div class="sub-text">Created on: {{ item.created_at | date }}</div>
                        </div>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <g id="Iconsax/Linear/arrowdown2">
                                    <path id="Vector" d="M5.9668 2.72027L10.3135 7.06694C10.8268 7.58027 10.8268 8.42027 10.3135 8.93361L5.9668 13.2803" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                            </svg>
                        </div>
                    </div>
                    </router-link>
                </div>
            </div>
        </b-modal>
        <b-modal
            id="preview_payslip"
            ref="preview_payslip"
            title=""
            hide-footer
            no-close-on-backdrop
            header-class="modal-header-custom"
            body-class="modal-body-custom-invoice-pdf"
            footer-class="modal-footer-custom"
            size="lg"
        >
            <div class="">
                <div class="text-black" style="font-size: 14px;margin-bottom: 25px;line-height: 16px;letter-spacing: 0.70px;word-wrap: break-word;"># {{merchant_ref}}</div>
            </div>
            <div class="border w-100" style="height: 90%;" >
                <div class="d-flex h-100 justify-content-center">
                    <embed :src="pdf_link" v-if="pdf_link" type="application/pdf" width="100%" height="100%" />
                </div>
            </div>
        </b-modal>
    </Layout>
</template>

<style>
    .due-status{
        background: rgba(252, 107, 45, 0.10);
        color: #FC6B2D;
    }
    .sent-status{
        background: rgba(57, 131, 172, 0.10);
        color: #3983AC;
    }
    .created-status{
        background: rgba(220, 218, 213, 0.50);
        color: rgba(0, 0, 0, 0.70);
    }
    .draft-status{
        background: rgba(220, 218, 213, 0.50);
        color: rgba(0, 0, 0, 0.70);
    }
    .paid-late-status{
        background:  rgba(155.09, 155.09, 245.68, 0.10);
        color: #7171E0;
    }
    .select-template-container{
        background: white;
        box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.30);
        border-radius: 12px;
        position: absolute;
        top: 57px;
        padding: 18px 12px;
        width: 290px;
        z-index: 4;
        right: 0px;
    }
    .select-template-section{
        border-radius: 8px;
        padding: 10px 12px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .select-template-section:hover {
        background: rgba(220, 218, 213, 0.30);
    }
    .select-template-section .name{
        line-height: 20px;
    }
    .select-template-section .sub-text{
        color: rgba(0, 0, 0, 0.50);
        font-size: 12px;
        font-weight: 325;
        line-height: 20px;
    }
    .select-template-section:hover .name{
        color: #FC6B2D;
    }
    .template-section-img{
        display: none;
    }
    .select-template-section:hover .template-section-img{
        display: block;
    }
    .modal-header-custom{
        border-bottom: none;
        padding-bottom: 0px;
    }
    .modal-body-custom-invoice{
        padding: 0px 30px 30px 30px;
        min-height: calc(100vh - 120px);
    }
    .modal-body-custom-invoice-pdf{
        padding: 0px 30px 30px 30px;
        height: calc(100vh - 120px);
    }
    .modal-footer-custom{
        border-top: none;
        padding: 0px 55px 25px 55px;
    }
    .template-section{
        padding: 10px 25px;
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-radius: 12px;
        border: 2px rgba(220, 218, 213, 0.30) solid;
        margin-top: 25px;
    }
    .template-section .name{
        line-height: 24px;
    }
    .template-section .sub-text{
        color: rgba(0, 0, 0, 0.30);
        font-size: 10px;
        font-family: Gotham;
        font-weight: 325;
        line-height: 12px;
    }
    .template-section:hover{
        background: rgba(220, 218, 213, 0.30);
        border: 2px #DCDAD5 solid;
    }
    .show_details button{
        padding: 7px 9px;
        border-radius: 8px;
        background: rgba(220, 218, 213, 0.30);
        height: 36px;
    }
    .show_details ul{
        box-shadow: 0px 0px 15px -5px rgba(0, 0, 0, 0.30);
        border-radius: 12px;
        padding-top: 12px;
        padding-bottom: 12px;
        transform: translate3d(-138px,40px,0)!important;
        z-index: 1!important;
    }
    .show_details ul button{
        padding: 12px 25px;
        border-radius: 0px;
        background-color: transparent;
        height: auto;
    }
    .overdue_reminder .dropdown-item{
        white-space: break-spaces;
        padding: 25px;
        height: auto !important;
    }
    .overdue_reminder button{
        padding: 0px;
        background: transparent;
        padding-left: 10px;
    }
    .overdue_reminder .dropdown-item:hover {
        color: inherit;
        border-right: 0px;
        background: #fff;
    }
    .overdue_reminder ul{
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
        border-radius: 12px;
        border: 1px rgba(220, 218, 213, 0.50) solid;
        padding: 0px;
        width: 245px;
    }
    ::v-deep #preview_payslip .modal-dialog{
        max-width: 700px;
    }
    .refunded{
        background: rgba(222, 53, 53, 0.10); 
        color: #DE3535;
    }
    .partial-refund{
        background: rgba(227, 179, 7, 0.10);
        color: #C59900;
    }
    .badge-soft-success{
        background: rgba(33, 150, 83, 0.10);
        color: #219653;
    }
    .badge-soft-danger{
        background: rgba(222, 53, 53, 0.10);
        color: #DE3535;
    }
</style>